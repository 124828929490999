import { createGlobalStyle } from 'styled-components';

import FuturaBold from '../static/FuturaPT-Bold.woff';
import FuturaBold2 from '../static/FuturaPT-Bold.woff2';
import FuturaBoldObl from '../static/FuturaPT-BoldObl.woff';
import FuturaBoldObl2 from '../static/FuturaPT-BoldObl.woff2';
import FuturaMed from '../static/FuturaPT-Medium.woff';
import FuturaMed2 from '../static/FuturaPT-Medium.woff2';
import Bodoni from '../static/BodoniSvtyTwoITCTT-Bold.woff';
import Bodoni2 from '../static/BodoniSvtyTwoITCTT-Bold.woff2';

export default createGlobalStyle`
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
    font-family: 'Futura';
    src: url(${FuturaBold2}) format('woff2'),
        url(${FuturaBold}) format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Futura Italic';
    src: url(${FuturaBoldObl2}) format('woff2'),
        url(${FuturaBoldObl}) format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Bodoni';
    src: url(${Bodoni2}) format('woff2'),
        url(${Bodoni}) format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Futura Medium';
    src: url(${FuturaMed2}) format('woff2'),
        url(${FuturaMed}) format('woff');
    font-weight: 500;
    font-style: normal;
}

::selection { background: #fca1a1; }
`;
