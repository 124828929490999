import React, { Component } from 'react';
import Box from 'components/box';
import Layout from 'components/layout';

class NotFound extends Component {
  componentDidMount() {
    if (typeof window !== 'undefined') {
      document.location.replace('/');
    }
  }

  render() {
    return (
      <Layout>
        <Box>Not found.</Box>
      </Layout>
    );
  }
}

export default NotFound;
